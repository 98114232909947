// libraries
import _ from 'lodash'
import orxe from 'openrosa-xpath-evaluator'
import MergeXML from 'mergexml'
import type { Dispatch, SetStateAction } from 'react'
import type { LazyQueryExecFunction, OperationVariables } from '@apollo/client'
import { map as awaityMap, reduce as awaityReduce } from 'awaity/esm'
import type { FFmpeg } from '@ffmpeg/ffmpeg'

// constants
import {
  ISSUE_FORM_CONTROL_TYPES,
  ISSUE_FORM_SPEC_REF_PREFIX,
  XFORM_BODY_UPLOAD_MEDIA_TYPE,
} from 'constants/issue'
import { SPEC_PARAMETERS_TYPES } from 'constants/common'
import { PROPERTY_VARIABLE_FORMATS } from 'constants/filter'
import {
  CUSTOM_WIDGETS_AND_FIELDS,
  FORM_QUESTION_TYPES,
} from 'constants/formBuilder'
import { FILE_UPLOAD_STATUS } from 'constants/fileUpload'

// utils
import { isInvalidValue, switchcaseF, toLowerCase } from 'helpers/utils'
import log, { reportException } from 'helpers/log'
import { getFileNameAndExtension, getShortId, uploadFiles } from 'helpers/files'
import { getAllJSONFormProperties } from 'helpers/issue'
import { isRepeaterWidgetField } from 'helpers/formResponse'

import type { FileUploadingState } from 'components/common/FileUploader'
import type { ID, Payload, SpecParams, SpecParamsValue } from 'types/common'
import type {
  XFormBodyNode,
  XFormBodyGroupNode,
  XFormBodyControlNode,
  XForm,
  XFormResult,
  XFormModel,
  XFormBind,
  XFormItemset,
  XFormSpec,
  XFormSpecificationParameters,
  JSONFormBody,
} from 'types/issue'
import type { JSONFormUiSchema } from 'types/formBuilder'

const convertSelectToEnum = ({ items }: XFormBodyControlNode) => {
  const options = _.map(items, item => ({
    key: item.value,
    displayName: item.label,
  }))
  return {
    options,
    creatable: false,
    isClearable: false,
    type: SPEC_PARAMETERS_TYPES.enum,
  }
}

const isReadOnly = (readOnly: string): boolean => readOnly === 'true()'
// https://xlsform.org/en/#question-types
// note	Display a note on the screen, takes no input. Shorthand for type=text with readonly=true.
const isNoteType = isReadOnly

const getFormControlSpecificSpec = (
  field: XFormBodyControlNode,
  bind: XFormBind
) => {
  const { control, mediatype } = field
  const { readonly } = bind || {}

  return switchcaseF({
    [ISSUE_FORM_CONTROL_TYPES.INPUT]: () => ({
      ...(isNoteType(readonly) && { visible: false }),
      type: 'string',
      questionType: FORM_QUESTION_TYPES.TEXT_INPUT,
    }),
    [ISSUE_FORM_CONTROL_TYPES.SELECT]: () => ({
      ...convertSelectToEnum(field),
      isMulti: true,
      separator: ' ',
      questionType: FORM_QUESTION_TYPES.DROPDOWN,
    }),
    [ISSUE_FORM_CONTROL_TYPES.SELECT1]: () => ({
      ...convertSelectToEnum(field),
      questionType: FORM_QUESTION_TYPES.DROPDOWN,
    }),
    [ISSUE_FORM_CONTROL_TYPES.UPLOAD]: () =>
      mediatype ===
        XFORM_BODY_UPLOAD_MEDIA_TYPE[PROPERTY_VARIABLE_FORMATS.image] && {
        format: PROPERTY_VARIABLE_FORMATS.image,
        type: 'string',
        questionType: FORM_QUESTION_TYPES.IMAGE_UPLOADER,
      },
  })(_.noop)(control)
}

export const getSpecificationParametersFromXForm = (
  xForm: XForm
): XFormSpec[] => {
  const { body, binds } = xForm || {}
  const bindsKeyByNodeSet = _.keyBy(binds, 'nodeset')
  const groupNodesRelevant = _.reduce(
    body,
    (acc, field) => {
      const { ref, childNodeIds } = field as XFormBodyGroupNode
      const { relevant } = bindsKeyByNodeSet[ref] || {}
      if (!childNodeIds || !relevant) return acc
      return {
        ...acc,
        ..._.zipObject(
          childNodeIds,
          _.fill(Array(childNodeIds.length), relevant)
        ),
      }
    },
    {} as { [key: ID]: string }
  )

  return _(body)
    .map((field: XFormBodyNode) => {
      const { id, label, hint, ref, itemset, childNodeIds } =
        field as XFormBodyGroupNode & XFormBodyControlNode
      if (!ref || ['end of form'].includes(toLowerCase(label)) || childNodeIds)
        return undefined

      const xFormBind = bindsKeyByNodeSet[ref] || {}
      const specificSpec = getFormControlSpecificSpec(
        field as XFormBodyControlNode,
        xFormBind
      )
      if (!specificSpec) return undefined

      const { relevant, type, required, readonly } = xFormBind
      const groupRelevant = groupNodesRelevant[id]
      const commonSpec = {
        visible: true,
        displayName: label,
        formFieldRef: ref,
        ...(groupRelevant && { groupRelevant }),
        ...(relevant && { relevant }),
        ...(type && { format: type }),
        ...(required && { necessity: isReadOnly(required) }),
        ...(readonly && { disabled: isReadOnly(required) }),
        ...(hint && { hint }),
        ...(itemset && { itemset }),
      }
      return {
        ...commonSpec,
        ...specificSpec,
        id: ref.replace(ISSUE_FORM_SPEC_REF_PREFIX, ''),
      }
    })
    .compact()
    .value()
}

const { evaluate: xFormEvaluate } = orxe()

const setFormValue = (
  xpathExpression: string,
  value: SpecParamsValue,
  formModelDom: Document
) => {
  const xpathResult = xFormEvaluate(
    xpathExpression,
    formModelDom,
    undefined,
    XPathResult.FIRST_ORDERED_NODE_TYPE
  )

  if (!xpathResult?.singleNodeValue) {
    log.error(
      `[updateIssueFormResultsInDom]${JSON.stringify(
        xpathExpression
      )} not found`
    )
    return
  }

  try {
    xpathResult.singleNodeValue.innerHTML = _.escape(value as string)
  } catch (e) {
    reportException(
      `Failed to set issue form value(${value}) to ${xpathExpression}. ${
        (e as Error).message
      }`
    )
  }
}

export const updateIssueFormResultsInDom = ({
  formModelDom,
  specificationParameters,
  payload,
}: {
  formModelDom: Document
  payload: SpecParams
  specificationParameters: XFormSpecificationParameters
}): void => {
  _.forEach(payload, (value, key) => {
    if (!specificationParameters[key]) return

    const newValue = _.isArray(value) ? value.join(' ') : value

    const xpathExpression = specificationParameters[key].formFieldRef as string
    setFormValue(`/${xpathExpression}`, newValue, formModelDom)
  })
}

export const parseXMLString = (
  domParser: DOMParser,
  formResultXml: string
): Document => domParser.parseFromString(formResultXml, 'application/xml')

const INSTANCE = /instance\(\s*(["'])((?:(?!\1)[A-z0-9.\-_]+))\1\s*\)/g

const getValidXPathExpression = (expression: string) => {
  return expression
    .replace(INSTANCE, (_match, _quote, id) => `/model/instance[@id="${id}"]`)
    .replaceAll('/data/', '//data/')
}

export const getXpathResult = (
  expression: string,
  xFormDom: Document,
  resultType: number
): XPathResult => {
  return xFormEvaluate(
    getValidXPathExpression(expression),
    xFormDom,
    undefined,
    resultType
  )
}

export const getXpathResultBoolean = (
  expression: string,
  xFormDom: Document
): {
  booleanValue: boolean
} => getXpathResult(expression, xFormDom, XPathResult.BOOLEAN_TYPE)

const getOptionsFromFormSpec = (
  itemset: XFormItemset,
  xFormModelDom: Document
) => {
  const { iterateNext } = getXpathResult(
    itemset.nodeset,
    xFormModelDom,
    XPathResult.ORDERED_NODE_SNAPSHOT_TYPE
  )
  let node = iterateNext()
  const options = []
  while (node) {
    const { labelRef, valueRef } = itemset
    const nodeObj = _.reduce(
      node.childNodes,
      (result, childnode) => {
        const { nodeName, innerHTML } = childnode as HTMLElement
        return { ...result, [nodeName]: innerHTML }
      },
      {} as Payload
    )
    options.push({
      displayName: nodeObj[labelRef],
      key: nodeObj[valueRef],
    })

    node = iterateNext()
  }

  return options
}

const shouldDisplaySpec = ({
  relevant,
  groupRelevant,
  visible,
  xFormModelDom,
}: {
  visible?: boolean
  relevant?: string
  groupRelevant?: string
  xFormModelDom: Document
}) => {
  if (!relevant && !groupRelevant) return visible

  if (
    groupRelevant &&
    !getXpathResultBoolean(groupRelevant, xFormModelDom).booleanValue
  ) {
    return false
  }

  return relevant
    ? getXpathResultBoolean(relevant, xFormModelDom).booleanValue
    : true
}

export const getUpdatedSpecificationParameters = ({
  xFormModelDom,
  specificationParameters,
}: {
  xFormModelDom: Document
  specificationParameters: XFormSpecificationParameters
}): XFormSpecificationParameters => {
  if (!xFormModelDom) return specificationParameters

  return _.reduce(
    specificationParameters,
    (acc, cur, key) => {
      const { relevant, groupRelevant, itemset, visible } = cur
      const newSpecPayload = {}
      if (itemset?.nodeset) {
        const options = getOptionsFromFormSpec(itemset, xFormModelDom)
        _.set(newSpecPayload, 'options', options)
      }

      const isVisible = shouldDisplaySpec({
        relevant,
        groupRelevant,
        visible,
        xFormModelDom,
      })
      _.set(newSpecPayload, 'visible', isVisible)

      return { ...acc, [key]: { ...cur, ...newSpecPayload } }
    },
    specificationParameters
  )
}

export const getModelDataInstance = (model: Document): Node | null =>
  model.querySelector('instance > *')

export const updateFormModelDataResult = (
  formModel: XFormModel,
  formResult: XFormResult
): Document => {
  const domParser = new DOMParser()
  const model = parseXMLString(
    domParser,
    _.replace(formModel, /\s(xmlns=("|')[^\s>]+("|'))/g, ' data-$1')
  )
  if (!formResult) return model

  let modelInstanceChildEl = getModelDataInstance(model)
  if (modelInstanceChildEl) {
    const merger = new MergeXML()
    const modelInstanceChildStr = new XMLSerializer().serializeToString(
      modelInstanceChildEl
    )
    merger.AddSource(modelInstanceChildStr)
    merger.AddSource(formResult)
    const { error } = merger
    if (merger.Get(1)) {
      // Remove the primary instance childnode from the original model
      model.querySelector('instance')?.removeChild(modelInstanceChildEl)
      // adopt the merged instance childnode
      const mergeResultDoc = parseXMLString(domParser, merger.Get(1))

      modelInstanceChildEl = model.adoptNode(mergeResultDoc.documentElement)
      // append the adopted node to the primary instance
      const modelInstanceEl = model.querySelector('instance')
      modelInstanceEl?.appendChild(modelInstanceChildEl)
    } else {
      reportException(
        `Merge issue form result to form model failed.${error?.text}`
      )
    }
  }

  return model
}

/** Gets names of all form fields that contain media data */
const getMediaFieldNames = (uischema: JSONFormUiSchema) =>
  Object.keys(uischema).reduce<string[]>((acc, key) => {
    const fieldUISchema = uischema[key]
    // TODO: Add an image widget when implementing an UI for picture upload
    return [CUSTOM_WIDGETS_AND_FIELDS.videos].includes(
      fieldUISchema?.['ui:widget']
    )
      ? [...acc, key]
      : acc
  }, [])

/** Gets a video duration using HTML5 video metadata */
const getVideoDuration = (file: File): Promise<number | undefined> =>
  new Promise(resolve => {
    const reader = new FileReader()

    reader.onload = event => {
      const video = document.createElement('video')
      if (!event.target || !event.target.result) {
        resolve(undefined)
        return
      }
      video.src = event.target.result.toString()

      video.onloadedmetadata = () => {
        resolve(video.duration)
      }

      video.onerror = error => {
        resolve(undefined)
      }
    }

    reader.readAsDataURL(file)
  })

type VideoMetadata = {
  duration: number | undefined
  size: number
  isPlayable: boolean
}

const getVideoMetadata = async (
  videos: File[]
): Promise<Record<string, VideoMetadata>> =>
  awaityReduce(
    videos,
    async (acc: Record<string, VideoMetadata>, videoFile: File) => {
      const duration = await getVideoDuration(videoFile)

      return {
        ...acc,
        [videoFile.name]: {
          duration,
          size: videoFile.size,
          // If a browser is able to parse video's metadata, that means it's playable
          isPlayable: _.isNumber(duration),
        },
      }
    },
    {}
  )

export const uploadIssueFormMedia = async ({
  jsonForm,
  mediaToUploadByField,
  formResultIssueId,
  rawFormData,
  ffmpeg,
  uploadFormMediaQuery,
  setFileUploadingState,
}: {
  jsonForm: JSONFormBody
  mediaToUploadByField: Record<string, File[]>
  formResultIssueId: string
  rawFormData: SpecParams
  ffmpeg?: FFmpeg | null
  uploadFormMediaQuery: LazyQueryExecFunction<Payload, OperationVariables>
  setFileUploadingState: Dispatch<SetStateAction<FileUploadingState>>
}): Promise<string> => {
  if (!ffmpeg) {
    throw new Error('FFMPEG was not initialized')
  }

  const { uischema = {} } = jsonForm
  // Collecting all files that need to be uploaded
  const rawAccumulatedFiles = _.flatten(Object.values(mediaToUploadByField))

  const generatedFileNamesToOldMap: Record<string, string> = {}
  const oldFileNamesToGeneratedMap: Record<string, string> = {}
  const mediaKeys: string[] = []

  const videosMetadata = await getVideoMetadata(rawAccumulatedFiles)

  const accumulatedFiles = await awaityMap(
    rawAccumulatedFiles,
    async (file: File) => {
      const metadata = videosMetadata[file.name]

      // If a video is already playable, that means we don't need to transcode it
      if (metadata.isPlayable) {
        generatedFileNamesToOldMap[file.name] = file.name
        oldFileNamesToGeneratedMap[file.name] = file.name
        mediaKeys.push(file.name)

        return file
      }

      // Using the original name to make the loading state work in the File Uploader
      setFileUploadingState(prevState => ({
        ...prevState,
        [file.name]: FILE_UPLOAD_STATUS.UPLOADING,
      }))
      const { fileName } = getFileNameAndExtension(file.name)
      const newFileName = `${fileName}-${getShortId()}.mp4`

      console.time(newFileName)
      const originalFileData = new Uint8Array(await file.arrayBuffer())
      await ffmpeg.writeFile(file.name, originalFileData)
      await ffmpeg.exec(['-i', file.name, '-c:v', 'libx264', newFileName])
      const newFileData = (await ffmpeg.readFile(newFileName)) as Uint8Array
      console.timeEnd(newFileName)

      generatedFileNamesToOldMap[newFileName] = file.name
      oldFileNamesToGeneratedMap[file.name] = newFileName
      mediaKeys.push(newFileName)

      return new File([newFileData.buffer], newFileName)
    }
  )

  if (mediaKeys.length > 0) {
    // Getting AWS URLs to upload files
    const queryResult = await uploadFormMediaQuery({
      variables: { issueId: formResultIssueId, mediaFilePaths: mediaKeys },
    })
    // Getting the media data from 'statesData'
    const dataCollectionFormMediaUploadUrls =
      _.first(_.flatMap(queryResult?.data?.issues?.byId?.statesData))
        ?.dataCollectionFormMediaUploadUrls || []

    if (dataCollectionFormMediaUploadUrls.length > 0) {
      // Actually uploading files
      const fileUploadErrors = await uploadFiles({
        files: accumulatedFiles,
        mediaUploadUrls: dataCollectionFormMediaUploadUrls,
        setFileUploadingState,
        originalFileNamesMap: generatedFileNamesToOldMap,
      })
      // Terminate if there is a problem with uploading files
      if (fileUploadErrors.length > 0) {
        throw new Error(JSON.stringify(fileUploadErrors))
      }
    }
  }

  // Preparing media fields to be saved as a form data
  const updatedMediaFields = Object.keys(mediaToUploadByField).reduce(
    (acc, key) => {
      const files = mediaToUploadByField[key]
      const newMediaData = files.map(({ name }) => ({
        // Don't forget to use unique generated file names
        mediaKey: oldFileNamesToGeneratedMap[name],
        // Using an empty string to satisfy the schema
        description: '',
      }))
      const oldMediaData = (rawFormData[key] || []) as {
        mediaKey: string[]
        description: string
      }[]

      return {
        ...acc,
        // Add new media + preserving previous media
        [key]: _.uniqBy([...oldMediaData, ...newMediaData], 'mediaKey'),
      }
    },
    {}
  )

  // Updating form data with media fields
  const updatedFormData: Record<string, unknown> = {
    ...rawFormData,
    ...updatedMediaFields,
  }

  const mediaFieldNames = getMediaFieldNames(uischema)

  // Remove deleted files if there are any
  mediaFieldNames.forEach(key => {
    const fieldValue = updatedFormData[key]
    if (_.isArray(fieldValue))
      updatedFormData[key] = fieldValue.filter(
        ({ isDeleted = false }) => !isDeleted
      )
  })

  return JSON.stringify(updatedFormData)
}

/** Assigns default values to fields like 'currentDatetime' */
export const assignDefaultValues = (
  formData: Payload | undefined,
  jsonForm?: JSONFormBody
): Payload | undefined => {
  const isCurrentDatetimeInSchema =
    !!jsonForm?.schema.properties.currentDatetime
  const shouldSetCurrentDatetime =
    isCurrentDatetimeInSchema && formData && !formData.currentDatetime

  if (shouldSetCurrentDatetime) {
    formData.currentDatetime = new Date().toISOString()
  }

  return formData
}

export const processDataBeforeSaving = (
  formData: Payload | undefined,
  jsonForm?: JSONFormBody
) => {
  if (!jsonForm || !formData) return formData

  const formDataWithDefaultValues = assignDefaultValues(
    formData,
    jsonForm
  ) as Payload
  // Collecting all properties (incl from 'dependencies')
  // and their schema in a object { [propertyKey]: propertySchema }
  const jsonFormProperties = getAllJSONFormProperties(jsonForm)

  // Cloning the formData object to make sure that we're not changing the original one
  const processedFormData = _.cloneDeep(formDataWithDefaultValues)

  _.forEach(jsonFormProperties, (propertySchema, propertyKey) => {
    const fieldValue = formDataWithDefaultValues[propertyKey]

    if (isRepeaterWidgetField(propertySchema) && !_.isEmpty(fieldValue)) {
      // Filtering out empty elements from an array
      processedFormData[propertyKey] = _.reject(
        fieldValue as unknown[],
        isInvalidValue
      )
    }
  })

  return processedFormData
}
