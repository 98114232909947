// libraries
import { useMemo, ReactElement, lazy, Suspense, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import * as Sentry from '@sentry/react'
import { RecoilRoot } from 'recoil'
import { ThemeProvider, Theme } from '@emotion/react'
import { withLDProvider, useLDClient } from 'launchdarkly-react-client-sdk'
import _ from 'lodash'
import { ApolloProvider } from '@apollo/client'

// constants
import { APP_NAMES } from 'constants/common'

// utils
import {
  StateProvider,
  ConfigProvider,
  AbilityProvider,
  AuthenticationProvider,
  useAuthStateValue,
  StaticResourceProvider,
  useConfigStateValue,
} from 'contexts'
import { getBrandingColours } from 'helpers/colour'
import { useBranding, useCurrentUser } from 'hooks'
import { lazyWithRetry } from 'routers/utils'
import { useInitApolloClient } from 'apollo/hooks'

// components
import { AppError, Loading } from 'components/common'
import HotToaster from 'components/common/Toast/HotToaster'

// styles
import 'mapbox-gl/dist/mapbox-gl.css'
import variables from 'SUExplorer.module.scss'

const MissionControlStudio = lazy(() =>
  lazyWithRetry(() => import('app/MissionControlStudio'))
)

const MissionControlMethaneSolution = lazy(() =>
  lazyWithRetry(() => import('app/MissionControlMethaneSolution'))
)

const SUExplorer = () => {
  const { appName } = useAuthStateValue()

  const ldClient = useLDClient()

  const { currentUser } = useCurrentUser()

  const {
    logging: { environment },
  } = useConfigStateValue()

  const userInfo = useMemo(
    () => ({
      ..._.pick(currentUser, ['id', 'role', 'email', 'name', 'group']),
      environment,
    }),
    [currentUser, environment]
  )

  // now that the user has authenticated update with user information
  useEffect(() => {
    if (ldClient) {
      ldClient.identify({
        key: 'user',
        kind: 'user',
        ...userInfo,
      })
    }
  }, [ldClient, userInfo])

  useEffect(() => {
    if (window.userGuiding) {
      window.userGuiding.identify(userInfo.id, userInfo)
    }
  }, [userInfo])

  const App =
    appName === APP_NAMES.methane
      ? MissionControlMethaneSolution
      : MissionControlStudio

  const sensorUpLogo = (
    <a
      href='https://sensorup.com'
      target='_blank'
      rel='noreferrer'
      className={variables.sensorUpLogo}
    >
      <img src='/assets/logo/SensorUp-Powered-by-Logo.svg' alt='SensorUp' />
    </a>
  )

  return (
    <Suspense fallback={<Loading />}>
      <App />
      <HotToaster />
      {sensorUpLogo}
    </Suspense>
  )
}

const ThemedSuExplorer = (): ReactElement => {
  const { title, colour = {} } = useBranding()

  const adjustedColour = useMemo(() => {
    const { primary } = colour || {}

    return {
      ...colour,
      ...getBrandingColours(primary),
      ..._.omit(variables, 'app'),
    } as Theme
  }, [colour])

  return (
    <ThemeProvider theme={adjustedColour}>
      <AuthenticationProvider>
        <>
          <Helmet>
            <title>{title || 'SensorUp Mission Control'}</title>
          </Helmet>
          <SUExplorer />
        </>
      </AuthenticationProvider>
      <style>
        {`:root {
              --primary-100: ${adjustedColour['primary-100']};
              --primary-500: ${adjustedColour['primary-500']};
              --secondary-light-500: ${adjustedColour['secondary-light-500']};
              --secondary-light-600: ${adjustedColour['secondary-light-600']};
            }`}
      </style>
    </ThemeProvider>
  )
}

const ContextualizedSuExplorer = (): ReactElement => {
  const apolloClient = useInitApolloClient()
  return (
    <ApolloProvider client={apolloClient}>
      <StaticResourceProvider>
        <StateProvider>
          <AbilityProvider>
            <ThemedSuExplorer />
          </AbilityProvider>
        </StateProvider>
      </StaticResourceProvider>
    </ApolloProvider>
  )
}

const FeatureFlaggedSuExplorer = (): ReactElement => {
  const { launchDarkly } = useConfigStateValue()

  const launchDarklyClientId = useMemo(() => {
    return _.get(launchDarkly, 'client_id')
  }, [launchDarkly])

  const App = useMemo(() => {
    const ldConfig = {
      clientSideID: launchDarklyClientId as string,
    }
    return withLDProvider(ldConfig)(ContextualizedSuExplorer)
  }, [launchDarklyClientId])

  return <App />
}

const SUExplorerContainer = (): ReactElement => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => <AppError message={error.toString()} />}
    >
      <RecoilRoot>
        <ConfigProvider>
          <FeatureFlaggedSuExplorer />
        </ConfigProvider>
      </RecoilRoot>
    </Sentry.ErrorBoundary>
  )
}

export default SUExplorerContainer
